.telAddition {
    
    font-size: 17px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    transition: all .4s ease;
}
.bigScreenTel {
    color: #161827;
}

.smallScreenTel{
    color: #ffffff;
}
.colorBack {
    color: #57b957;
    font-weight: bold;
}

.main-header .top-header .right-info i, .main-header .top-header .left-info i {
    font-weight: bold;
}

