button.slick-prev:before, button.slick-next:before {
    width: 50px;
    height: 50px;
    font-size: 50px;
    color: #FFFFFF;
  }


  #aboutRemoveBorderModal {
    border-bottom-width: 0px;
}

.addHeight {
  height: 150px !important;
  padding-top: 10px;
}